import { ThemeProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useKeycloak } from 'keycloak-react-web';
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';
import FullPageMessage from '~/components/FullPageMessage';
import AppConfig from '~/config/AppConfig';
import MetaProvider from '~/utils/DataDictionary/index';
import theme from './theme';
import useQueryAgent from './hooks/useQueryAgent';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      retryOnMount: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      structuralSharing: false,
      staleTime: 0,
    },
  },
});

const AppProvider = createContext({});

const { VITE_SECURE_TOKEN_COOKIE_NAME } = import.meta.env;

// eslint-disable-next-line react-refresh/only-export-components
export function useAppContext() {
  return useContext(AppProvider);
}

export default function AppProviders({ children }) {
  const { keycloak, initialized } = useKeycloak();
  const [appTitle, setAppTitle] = useState('Manage');
  const { fetchDataDictionary } = useQueryAgent();

  const fetchAccountData = async () => {
    try {
      const res = await fetchDataDictionary(['/account']);
      const { data } = res;
      if (data) localStorage.setItem('currency', data.currency);
    } catch (error) {
      console.error('Failed to fetch data:', error);
      localStorage.setItem('currency', 'INR');
    }
  };

  useEffect(() => {
    if (!initialized) return;
    if (!keycloak.authenticated) {
      // keycloak.login();
      return;
    }
    sessionStorage.setItem(VITE_SECURE_TOKEN_COOKIE_NAME, keycloak.token);
    fetchAccountData();
  }, [initialized, keycloak]);

  if (!initialized || !keycloak.authenticated)
    return (
      <FullPageMessage
        title="Authenticating..."
        showLoader={true}
      />
    );

  const { snackbarAutoHideDuration } = AppConfig;
  // const { token } = useAuthenticateUser();
  const logoutAppSession = async () => {
    try {
      const userInfo = await keycloak.loadUserInfo();
      keycloak.logout(userInfo);
      localStorage.clear();
    } catch (error) {
      keycloak.logout();
      localStorage.clear();
    }
  };
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const AppProviderValues = {
    logoutAppSession,
    keycloak,
    token: keycloak?.token,
    appTitle,
    setAppTitle,
  };
  // Return JSX
  return (
    <AppProvider.Provider value={AppProviderValues}>
      <MetaProvider token={keycloak?.token || ''}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={snackbarAutoHideDuration}
              disableWindowBlurListener={true}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>{children}</LocalizationProvider>
            </SnackbarProvider>
            {/* <ReactQueryDevtools position="bottom-right" /> */}
          </QueryClientProvider>
        </ThemeProvider>
      </MetaProvider>
    </AppProvider.Provider>
  );
}

// Write PropTypes for AppProviders
AppProviders.propTypes = {
  children: PropTypes.node.isRequired,
};
