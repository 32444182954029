import { Box, Container } from '@mui/material';
import BannerImage from '../../../assets/products-masthead.jpg';
import VmaxLogo from '../../../assets/vmax-logo.svg';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import VservProduct from '../../../assets/vserv-product.svg';
import '../../../styles/main.css';
import { MobileRealmDetails } from '../../../enums/MobileRealmDetails';
import Card from './Card';

const { VITE_REALM_SUPPORT_EMAIL, VITE_REALM_LOGO } = import.meta.env;

export default function MobileRealmView() {
  return (
    <Container className="mobile-container">
      <Box
        textAlign="left"
        sx={{ margin: '10px 20px' }}>
        <img
          src={VITE_REALM_LOGO}
          alt="VMAX"
          className="vmax-logo"
        />
      </Box>
      <Box
        textAlign="center"
        sx={{ width: '100%' }}
        mb={2}>
        <img
          src={BannerImage}
          alt="User"
          style={{ width: '100%' }}
        />
      </Box>
      <div className="box-conainer">
        {MobileRealmDetails.map((item, index) => (
          <Card
            key={index}
            logo={item.logo}
            description={item.description}
          />
        ))}
      </div>
      <div className="alert">
        <div className="alert-header">
          <img
            src={VmaxLogo}
            alt="VMAX"
          />
        </div>
        <div className="alert-main">
          <div className="alert-icon">
            <ReportProblemIcon />
          </div>
          <div className="content">
            <h4>Login is disabled on Mobile Site!</h4>
            <p>Kindly access the desktop site for a seamless login experience.</p>
          </div>
        </div>
        <div className="alert-footer">
          <p>
            Need Support?{' '}
            <a
              href={`mailto:${VITE_REALM_SUPPORT_EMAIL}`}
              target="_blank"
              rel="noreferrer">
              Email us
            </a>
          </p>
          <a
            href="https://vserv.com"
            target="_blank"
            rel="noopener noreferrer">
            <img src={VservProduct} />
          </a>
        </div>
      </div>
    </Container>
  );
}
