import { List, ListItem, ListSubheader } from '@mui/material';
import ADXIcon from '../assets/adx_logo.png';
import DealIcon from '../assets/handshake.png';
import MarketerIcon from '../assets/pie_chart.png';
import NetworkIcon from '../assets/scatter_plot.png';
import '../styles/hamburger.css';
import HamburgerButton from './HamburgerButton';

export default function HamburgerMenuList() {
  const { VITE_MARKETER_SEGMENT_DISABLED: isMarketerSegmentDisabled } = import.meta.env;

  return (
    <List className="hamburger-menu-list">
      <ListSubheader>Demand</ListSubheader>

      <ListItem
        disablePadding
        className="hamburger-menu-item">
        <HamburgerButton
          icon={DealIcon}
          listingPath="/"
          createPath="/create-direct-deal"
          listingId="button-listing-0"
          creatingId="button-create-0"
          listingTitle="Direct Deals"
          createTitle="Create Direct Deal"
        />
      </ListItem>

      <ListItem
        disablePadding
        className="hamburger-menu-item">
        <HamburgerButton
          icon={ADXIcon}
          listingPath="/adx-deals"
          createPath="/create-adx-deal"
          listingId="button-listing-1"
          creatingId="button-create-1"
          listingTitle="AdX Deals"
          createTitle="Create AdX Deal"
        />
      </ListItem>

      <ListItem
        disablePadding
        className="hamburger-menu-item">
        <HamburgerButton
          icon={NetworkIcon}
          listingPath="/network-line-items"
          createPath="/create-network-line-item"
          listingId="button-listing-2"
          creatingId="button-create-2"
          listingTitle="Network Line Items"
          createTitle="Create Network Line Item"
        />
      </ListItem>

      {!isMarketerSegmentDisabled && (
        <>
          <ListSubheader>Segments</ListSubheader>

          <ListItem
            disablePadding
            className="hamburger-menu-item">
            <HamburgerButton
              icon={MarketerIcon}
              listingPath="/marketer-segments"
              createPath="/marketer-segments?create"
              listingId="button-listing-3"
              creatingId="button-create-3"
              listingTitle="Marketer Segments"
              createTitle="Create Marketer Segment"
            />
          </ListItem>
        </>
      )}
    </List>
  );
}
