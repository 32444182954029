import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import TextMaskCustom from './TextMaskCustom';

export default function TextBoxField({
  label,
  name,
  control,
  InputPropsCustom,
  className,
  ...restParams
}) {
  const { mask = {} } = restParams;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
        const inputProps = mask?.mask && {
          InputProps: {
            inputComponent: TextMaskCustom,
            inputProps: {
              onChange,
              ...restParams,
            },
            ...InputPropsCustom,
          },
        };
        return (
          <TextField
            helperText={error ? error.message : null} // undefined | ErrorObject
            error={!!error}
            value={value}
            onChange={value => onChange(value)}
            onBlur={onBlur}
            fullWidth
            label={label}
            className={className}
            {...(inputProps || {})}
            {...restParams}
          />
        );
      }}
    />
  );
}

// Write PropTypes for InputText
TextBoxField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  InputPropsCustom: PropTypes.object,
  className: PropTypes.string,
};
