import { Menu } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import HamburgerMenuList from './HamburgerMenuList';

export default function Hamburger() {
  const [open, setOpen] = useState(false);
  const closeMenu = () => {
    setOpen(false);
  };
  const toggleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <IconButton
        edge="start"
        id="button-menu"
        color="inherit"
        aria-label="menu"
        onClick={toggleClick}>
        {!open ? <Menu /> : <CloseIcon />}
      </IconButton>
      <Drawer
        className="menu"
        anchor="left"
        open={open}
        onClose={closeMenu}>
        <Box
          role="presentation"
          onClick={toggleClick}
          onKeyDown={toggleClick}>
          <HamburgerMenuList />
        </Box>
      </Drawer>
    </>
  );
}
