import getAccountName from "~/utils/getAccountName";

const {
  VITE_API_BASE_URL,
  VITE_DATA_DICTIONARY_BASE_URL,
  VITE_ANALYTICS_API_BASE_URL,
  VITE_APP_URL,
  VITE_KEYCLOAK_REALM
} = import.meta.env;

const RealmName = VITE_KEYCLOAK_REALM || getAccountName() || '';

const BaseAPI = VITE_API_BASE_URL.replace('REALM_NAME', RealmName);
const AnalyticsBaseAPI = VITE_ANALYTICS_API_BASE_URL.replace('REALM_NAME', RealmName);
const AppUrl = VITE_APP_URL.replace('REALM_NAME', RealmName);
const DataDictionaryUrl = VITE_DATA_DICTIONARY_BASE_URL.replace('REALM_NAME', RealmName) + 'data-dictionary/';

window.Meta = {
  BaseAPI,
  AnalyticsBaseAPI,
  AppUrl,
};



const AppConfig = {
  apiBaseUrl: BaseAPI,
  apiDataDictionaryUrl: DataDictionaryUrl,
  snackbarAutoHideDuration: 10000,
  forms: {
    defaults: {
      mode: 'onBlur',
      revalidateMode: 'onBLur',
    },
    setValueProps: {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    },
    dateFormat: 'YYYY-MM-DD HH:mm',
    timeFormat: 'HH:mm',
    variant: 'standard',
  },
  Chip: {
    variant: 'outlined',
  },
};


Object.freeze(AppConfig);
export default AppConfig;
