import { useAppContext } from '~/AppProviders';
// import { RedirectToLogin } from '~/auth';
import axios from 'axios';
import AppConfig from '~/config/AppConfig';

const query = axios.create({
  baseURL: AppConfig.apiBaseUrl,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const dataDictionaryQuery = axios.create({
  baseURL: AppConfig.apiDataDictionaryUrl,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default function useQueryAgent() {
  const { logoutAppSession, token } = useAppContext();
  query.defaults.headers.Authorization = `Bearer ${token}`;
  dataDictionaryQuery.defaults.headers.Authorization = `Bearer ${token}`;
  query.interceptors.response.use(
    response => response,
    async error => {
      if (error?.response?.status === 401) return logoutAppSession();
      return Promise.reject(error);
    }
  );

  dataDictionaryQuery.interceptors.response.use(
    response => response,
    async error => {
      if (error?.response?.status === 401) return logoutAppSession();
      return Promise.reject(error);
    }
  );
  return {
    get: ([apiName, data]) => query.get(apiName, { params: { ...(data || {}) } }),
    fetchDataDictionary: ([apiName, data]) => dataDictionaryQuery.get(apiName, { params: { ...(data || {}) } }),
    update: ([uri, data]) => query.patch(uri, { ...data }),
    archive: ([uri, data]) => query.delete(uri, { ...data }),
    post: ([uri, data]) => query.post(uri, { ...data }),
  };
}
