import analytics from '~/assets/analytics.png';
import manageApp from '~/assets/manage-app.png';
import setup from '~/assets/setup.png';

const REALMNAME = localStorage.getItem('realm_name') || import.meta.env.VITE_KEYCLOAK_REALM || '';

const UserApps = {
  1: {
    id: '1',
    title: 'Manage',

    icon: (
      <img
        src={manageApp}
        width={32}
        height={27}
        alt="Manage App Icon"
      />
    ),
    url: import.meta.env.VITE_APP_BASE_URL,
  },
  2: {
    id: '2',
    title: 'Setup',
    icon: (
      <img
        src={setup}
        width={32}
        height={27}
        alt="Setup App Icon"
      />
    ),
    url: import.meta.env.VITE_SETUP_APP_URL.replace('REALM_NAME', REALMNAME),
  },
  3: {
    id: '3',
    title: 'Analytics',
    icon: (
      <img
        src={analytics}
        width={33}
        height={27}
        alt="Analytics App Icon"
      />
    ),
    url: import.meta.env.VITE_ANALYTICS_APP_URL,
  },
};

export default UserApps;
