import Fast from '../assets/fr_icon.svg';
import AdRequest from '../assets/ar_icon.svg';
import Enterprise from '../assets/ea_icon.svg';
import NoCode from '../assets/nc_icon.svg';

const MobileRealmDetails = Object.freeze([
    {
        logo: Fast,
        description: 'Fastest Response Time. At Any Scale.',
    },
    {
        logo: AdRequest,
        description: 'Pay for Ad Requests, Not Features',
    },
    {
        logo: Enterprise,
        description: 'Enterprise Agnostic Payment Plan. No hidden cost.',
    },
    {
        logo: NoCode,
        description: 'No Code to Low Code Integration. Extensive Support.',
    }
]);

export { MobileRealmDetails };