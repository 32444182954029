import { Outlet } from 'react-router';
import ErrorBoundary from './ErrorBoundary';
import Header from './Header';
import ScrollToTop from './ScrollToTop';

const RouterRootComponent = () => {
  return (
    <>
      <Header />
      <ErrorBoundary>
        <ScrollToTop>
          <Outlet />
        </ScrollToTop>
      </ErrorBoundary>
    </>
  );
};

export default RouterRootComponent;
