import { Link } from 'react-router-dom';
import Plus from '../assets/plus.png';
import PropTypes from 'prop-types';
import '../styles/hamburger.css';
import { useLocation } from 'react-router-dom';

function HamburgerButton({
  icon,
  createPath,
  listingPath,
  listingId,
  creatingId,
  createTitle,
  listingTitle,
}) {
  const { pathname } = useLocation();
  const isListingPathMatch = pathname === listingPath;
  const isCreatePathMatch = pathname === createPath;

  return (
    <div className="hamburger-menu-link container">
      <Link
        className={`hamburger-listing ${isListingPathMatch && 'disabled'}`}
        id={listingId}
        to={isListingPathMatch ? '#' : listingPath}>
        <img
          src={icon}
          className="hamburger-menu-icon"
          alt={listingTitle}
        />
        <span>{listingTitle}</span>
      </Link>
      <Link
        className={`create-deal ${isCreatePathMatch ? 'disabled' : ''}`}
        id={creatingId}
        to={isCreatePathMatch ? '#' : createPath}>
        <span>
          <img
            src={icon}
            className="hamburger-menu-icon"
            alt={createTitle}
          />
          <span className="create-title">{createTitle}</span>
        </span>
        <img
          className="ad-icon"
          src={Plus}
          alt=""
        />
      </Link>
    </div>
  );
}

export default HamburgerButton;

HamburgerButton.propTypes = {
  icon: PropTypes.string,
  listingPath: PropTypes.string,
  createPath: PropTypes.string,
  createTitle: PropTypes.string,
  listingTitle: PropTypes.string,
  listingId: PropTypes.string,
  creatingId: PropTypes.string,
};
