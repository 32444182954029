import { CircularProgress, Paper, Typography } from '@mui/material';

export default function FullPageMessage({
  title = '',
  color = 'primary',
  showLoader = false,
  children,
}) {
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Paper
        elevation={3}
        style={{ padding: '20px', textAlign: 'center', minWidth: '75%' }}>
        {showLoader && <CircularProgress color={color} />}
        {title && (
          <Typography
            variant="h5"
            color={color}>
            {title}
          </Typography>
        )}
        {children && <Typography variant="body1">{children}</Typography>}
      </Paper>
    </div>
  );
}
