import KeycloakInstance from 'keycloak-js';

// Get constants from .env file
const {
  VITE_KEYCLOAK_IAM_URL,
  VITE_KEYCLOAK_REALM = '',
  VITE_KEYCLOAK_CLIENT_ID,
} = import.meta.env;

const KeycloakClient = new KeycloakInstance({
  url: VITE_KEYCLOAK_IAM_URL,
  realm: VITE_KEYCLOAK_REALM,
  clientId: VITE_KEYCLOAK_CLIENT_ID,
});

export default KeycloakClient;

export function getKeycloakClient(realmName) {
  const KeycloakClient = new KeycloakInstance({
    url: VITE_KEYCLOAK_IAM_URL,
    realm: realmName || VITE_KEYCLOAK_REALM,
    clientId: VITE_KEYCLOAK_CLIENT_ID,
  });
  return KeycloakClient;
}

export const KeycloakInitOptions = {
  onLoad: 'login-required',
  flow: 'implicit',
  pkceMethod: "S256",
};
