import { Button, Paper, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import RealmBannner from '../../assets/products-masthead.jpg';
import { FavIcon } from '~/enums/AppLogo';
import dayjs from 'dayjs';

const {
  VITE_KEYCLOAK_REALM,
  VITE_REALM_VALIDATION_BASE_URL,
  VITE_REALM_SUPPORT_EMAIL,
  VITE_REALM_LOGO,
} = import.meta.env;

export default function CheckRealmName({ state, children }) {
  const [realmName, setRealmName] = state;
  const [realmName2, setRealmName2] = useState(realmName);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  document.querySelector('#favicon').setAttribute('href', FavIcon.MANAGE);

  const onSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    const url = VITE_REALM_VALIDATION_BASE_URL.replace('REALM_NAME', realmName2);
    try {
      const response = await fetch(url, { method: 'HEAD' });

      if (response.status === 200) {
        setRealmName(realmName2);
        setRealmName2(realmName2);
        setIsLoading(false);
        // setLocalRealm(realmName2);
      } else {
        // Invalid realm
        setErrorMessage(
          "Account name is invalid or doesn't exist. Please contact your administrator"
        );
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error checking realm:', error);
      setErrorMessage('Error checking Account. Please try again later.');
      setIsLoading(false);
    }
  };

  if (!VITE_KEYCLOAK_REALM && !realmName) {
    return (
      <>
        <div className="realm-wrapper">
          <div className="realm-brand-logo">
            <img
              src={VITE_REALM_LOGO}
              className="realm-logo"
              alt="logo"
            />
          </div>
          <Paper
            elevation={5}
            className="realm-details">
            <div>
              <img
                src={RealmBannner}
                alt="image"
              />
            </div>
            <div className="realm-form">
              <Typography
                variant="subtitle1"
                className="realm-header"
                gutterBottom>
                Log in to your account
              </Typography>
              <form onSubmit={onSubmit}>
                <TextField
                  label="Account Name"
                  value={realmName2}
                  className="realm-input"
                  error={Boolean(errorMessage)}
                  onChange={e => setRealmName2(e.target.value)}
                  fullWidth
                  autoFocus={true}
                  helperText={errorMessage}
                />
                <p className="realm-submit-button">
                  <Button
                    disabled={isLoading}
                    type="submit"
                    variant="contained"
                    color="primary">
                    Next
                  </Button>
                </p>
                <small className="realm-email">
                  Need Support?{' '}
                  <a
                    href={`mailto:${VITE_REALM_SUPPORT_EMAIL}`}
                    target="_blank"
                    rel="noreferrer">
                    Email us
                  </a>
                </small>
              </form>
            </div>
          </Paper>
        </div>
        <div className="realm-footer">
          <p>&copy; {dayjs().year()}, All Rights Reserved</p>
        </div>
      </>
    );
  }
  return <>{children}</>;
}

CheckRealmName.propTypes = {
  state: PropTypes.array,
  children: PropTypes.node,
};
