import { useState } from 'react';
import { useNavigate } from 'react-router';

export default function useAppMenu() {
  const navigate = useNavigate();
  const [anchorElement, setAnchorElement] = useState(null);
  const handleClick = e => {
    setAnchorElement(e?.currentTarget);
  };
  const handleClose = () => {
    setAnchorElement(null);
  };

  const handleAppClick = (e, url) => {
    if(url.startsWith('http')) {
      window.open(url, '_blank');
    }else{
      navigate(url);
    }
    handleClose(e);
  };
  return {
    open: !!anchorElement,
    anchorElement,
    handleClick,
    handleClose,
    handleAppClick,
  };
}
