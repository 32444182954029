import { useAppContext } from '~/AppProviders';
import { useState } from 'react';

export default function useProfileMenu() {
  const { logoutAppSession } = useAppContext();
  const [anchorElement, setAnchorElement] = useState(null);
  const handleClick = e => {
    setAnchorElement(prevElement =>
      prevElement === e?.currentTarget ? null : e?.currentTarget
    );
  };
  const handleClose = () => {
    setAnchorElement(null);
  };
  // const handleLogOutClick = () => {
  //   localStorage.clear();
  //   RedirectToLogin();
  // };
  return {
    open: !!anchorElement,
    anchorElement,
    handleClick,
    handleClose,
    handleLogOutClick: logoutAppSession,
  };
}
