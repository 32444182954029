import { Box } from '@mui/system';
import { useForm } from 'react-hook-form';
import TextBoxField from '~/components/FormFields/TextBoxField';
import './network-line-item.css';
import { IconButton, Paper, Tooltip } from '@mui/material';
import PauseIcon from '@mui/icons-material/Pause';

export default function NetworkPage() {
  const { control } = useForm();
  return (
    <div className="network-wrapper">
      {/* Header */}
      <Box className="network-line-item-header">
        <TextBoxField
          className="network-name"
          variant="outlined"
          control={control}
          name="networkName"
          label="Network Name"
        />
        <Tooltip title="Pause">
          <IconButton color="primary">
            <PauseIcon />
          </IconButton>
        </Tooltip>
      </Box>
      {/* Details */}
      <Box className="network-line-item-details">
        <Paper className="choose-details">
          <h1>Section1</h1>
        </Paper>
        <Paper className="show-details">
          <h1>Section2</h1>
        </Paper>
      </Box>
      {/* Audience */}
      <Paper className="audience-section">
        <h1>Audience Section</h1>
      </Paper>
    </div>
  );
}
