/* eslint-disable no-param-reassign */
import axios from 'axios';
import { createContext, useContext } from 'react';

const { VITE_API_BASE_URL, VITE_DATA_DICTIONARY_BASE_URL, VITE_KEYCLOAK_REALM } = import.meta.env;

export const EnumDataDictionary = Object.freeze({
  // ACCOUNT: '/account',
  PUBLISHER: '/publisher',
  ASSET: '/asset',
  ADSPOT: '/adspot',
  AD_FORMATS: '/ad-formats',
  LABEL: '/label',
  CAMPAIGN_WEIGHTS: '/campaign-weights',
  COUNTRY: '/country',
  REGION: '/region',
  CITY: '/city',
  // CITY: countryId => `/country/${countryId}/city`,
  DEVICE_PLATFORMS: '/device-platforms',
  DEVICE_MAKE: '/device-make',
  CAMPAIGN_CLASS_DELIVERY_LEVELS: '/campaign-class-delivery-levels',
  USER_AGE: '/user-age',
  USER_GENDER: '/user-gender',
  CUSTOM_KEYS: '/custom-keys',
  TAXONOMY: '/taxonomy',
  AUDIENCE_TARGETING: '/targeting-dictionaries',
  ACCOUNT: '/account',
  CUSTOM_ATTRIBUTES: '/directdeals/attributes',
  NETWORK_CHANNELS: '/network-channels',
  NETWORK_ACCOUNTS: '/network-accounts',
  SSP_CHANNELS: '/ssp-channels',
  SSP_ACCOUNTS: '/ssp-accounts',
});

export const EnumDemandAPIs = Object.freeze({
  MARKETER: 'marketers',
  AGENCY: 'adagencies',
  BRAND: 'brand',
});

export const EnumAudienceInfo = {
  [EnumDataDictionary.TAXONOMY]: { type: 'user', name: 'taxonomy' },
  'marketer-segments': { type: 'user', name: 'taxonomy' },
  'connection-type': { type: 'device', name: 'connection_type' },
  [EnumDataDictionary.DEVICE_MAKE]: { type: 'device', name: 'make' },
  [EnumDataDictionary.DEVICE_PLATFORMS]: { type: 'device', name: 'platform' },
  [EnumDataDictionary.USER_AGE]: { type: 'user', name: 'age', isDemography: true },
  [EnumDataDictionary.USER_GENDER]: {
    type: 'user',
    name: 'gender',
    isDemography: true,
    noAutocompleteInput: true,
  },
  [EnumDataDictionary.COUNTRY]: { type: 'geo', name: 'country', isDemography: true },
  [EnumDataDictionary.REGION]: { type: 'geo', name: 'region', isDemography: true },
  [EnumDataDictionary.CITY]: { type: 'geo', name: 'city', isDemography: true },
  [EnumDataDictionary.CUSTOM_KEYS]: { type: 'custom_keys', name: false, isCustomKeys: true },
};

export function LookupAudienceObjectFromMeta({
  segmentName,
  operator = 'isAnyOf',
  value = [],
  fields = {},
}) {
  if (!segmentName || typeof segmentName !== 'string') return {};
  const info = EnumAudienceInfo?.[segmentName] || EnumAudienceInfo?.[`/${segmentName}`] || false;
  if (info === false) return {};
  const { type, name, isCustomKeys } = info;
  if (isCustomKeys === true) {
    const { key: customKey, operator: customOperator, values: customValues } = fields;
    return {
      [type]: {
        [customOperator]: {
          key: customKey,
          value: customValues,
        },
      },
    };
  }
  return {
    [type]: {
      [name]: {
        operator,
        value,
      },
    },
  };
}

export function getAudienceData() {
  const item = window.localStorage.getItem(EnumDataDictionary.AUDIENCE_TARGETING);
  const data = item ? JSON.parse(item) : [];
  return data;
}

const RealmName = sessionStorage.getItem('realm_name') || VITE_KEYCLOAK_REALM || '';

const DataDictionaryQuery = axios.create({
  baseURL: VITE_DATA_DICTIONARY_BASE_URL.replace('REALM_NAME', RealmName) + 'data-dictionary/',
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const DemandAPIQuery = axios.create({
  baseURL: VITE_API_BASE_URL.replace('REALM_NAME', RealmName),
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const MetaData = createContext(null);

// TODO: Need to remove this Meta Provider component
export default function MetaProvider({ token, children }) {
  // const {get} = useQueryAgent();

  return <MetaData.Provider value={token}>{children}</MetaData.Provider>;
}

export const useMetaData = () => {
  const methods = useContext(MetaData);
  return methods;
};

export function removeLeadingSlashChar(str) {
  if (typeof str !== 'string' || str.length === 0) return str;
  const newStr = str.replace(/^\//gi, '');
  return newStr;
}
