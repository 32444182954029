import React from 'react';
import AppLoader from './AppLoader';
import DictionaryProvider from '~/DictionaryProvider';
import PropTypes from 'prop-types';

const DictionaryWrapper = ({ children }) => {
  return (
    <React.Suspense fallback={<AppLoader />}>
      <DictionaryProvider>{children}</DictionaryProvider>
    </React.Suspense>
  );
};

DictionaryWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DictionaryWrapper;
