import React from 'react';
import { createBrowserRouter, defer, RouterProvider } from 'react-router-dom';
import NetworkPage from './demo/NetworkPage';
import DataDictionary from './enums/DataDictionary';
import fetchingDataDictionary from './utils/fetchingDataDictionary';
import DictionaryWrapper from './components/DictionaryWrapper';
import LoadModule from './components/LoadModule';
import RouterRootComponent from './components/RouterRootComponent';
import useQueryAgent from './hooks/useQueryAgent';
import { useAppContext } from './AppProviders';

const DirectDealListing = React.lazy(() => import('./pages/DirectDeals/Listing'));
const DirectDealManage = React.lazy(() => import('./pages/DirectDeals/Manage'));
const MarketerSegmentsListing = React.lazy(() => import('./pages/MarketerSegments/Listing'));
const Analytics = React.lazy(() => import('./pages/Analytics'));
const NetworkLineItemsListing = React.lazy(() => import('./pages/NetworkLineItems/Listing'));
const NetworkLineItemCreate = React.lazy(() => import('./pages/NetworkLineItems/Manage'));
const NetworkLineItemManage = React.lazy(() => import('./pages/NetworkLineItems/Manage'));
const NotFound = React.lazy(() => import('./pages/404'));
const AdXDealsListing = React.lazy(() => import('./pages/AdXDeals/Listing'));
const AdXDealCreate = React.lazy(() => import('./pages/AdXDeals/Manage'));
const AdXDealManage = React.lazy(() => import('./pages/AdXDeals/Manage'));

export default function AppRoutes() {
  const { token } = useAppContext();
  const { fetchDataDictionary } = useQueryAgent();
  const { fetchData } = fetchingDataDictionary(token, fetchDataDictionary);

  const RoutesMeta = createBrowserRouter([
    {
      element: <RouterRootComponent />,
      path: '/',
      children: [
        {
          path: '/',
          element: (
            <LoadModule>
              <DirectDealListing />
            </LoadModule>
          ),
        },
        {
          path: 'direct-deal/:id/:drawerId?/:drawerModalId?',
          element: (
            <DictionaryWrapper>
              <DirectDealManage />
            </DictionaryWrapper>
          ),
          loader: async () =>
            defer({
              data: fetchData(DataDictionary.DirectDealManage),
            }),
        },
        {
          path: 'create-direct-deal',
          element: (
            <DictionaryWrapper>
              <DirectDealManage />
            </DictionaryWrapper>
          ),
          loader: async () =>
            defer({
              data: fetchData(DataDictionary.DirectDealCreate),
            }),
        },
        {
          path: 'marketer-segments',
          element: (
            <LoadModule>
              <MarketerSegmentsListing />
            </LoadModule>
          ),
        },
        {
          path: 'analytics',
          element: (
            <DictionaryWrapper>
              <Analytics />
            </DictionaryWrapper>
          ),
          loader: async () =>
            defer({
              data: fetchData(DataDictionary.Analytics),
            }),
        },
        {
          path: 'network-line-items',
          element: (
            <DictionaryWrapper>
              <NetworkLineItemsListing />
            </DictionaryWrapper>
          ),
          loader: async () =>
            defer({
              data: fetchData(DataDictionary.NetworkItemListing),
            }),
        },
        {
          path: 'create-network-line-item',
          element: (
            <DictionaryWrapper>
              <NetworkLineItemCreate />
            </DictionaryWrapper>
          ),
          loader: async () =>
            defer({
              data: fetchData(DataDictionary.NetworkItemCreate),
            }),
        },
        {
          path: 'network-line-item/:id',
          element: (
            <DictionaryWrapper>
              <NetworkLineItemManage />
            </DictionaryWrapper>
          ),
          loader: async () =>
            defer({
              data: fetchData(DataDictionary.NetworkItemManage),
            }),
        },
        {
          path: 'adx-deals',
          element: (
            <LoadModule>
              <AdXDealsListing />
            </LoadModule>
          ),
        },
        {
          path: 'create-adx-deal',
          element: (
            <DictionaryWrapper>
              <AdXDealCreate />
            </DictionaryWrapper>
          ),
          loader: async () =>
            defer({
              data: fetchData(DataDictionary.AdXDealCreate),
            }),
        },
        {
          path: 'adx-deal/:id',
          element: (
            <DictionaryWrapper>
              <AdXDealManage />
            </DictionaryWrapper>
          ),
          loader: async () =>
            defer({
              data: fetchData(DataDictionary.AdXDealManage),
            }),
        },
        {
          path: 'demo',
          element: (
            <LoadModule>
              <NetworkPage />
            </LoadModule>
          ),
        },
      ],
    },
    {
      path: '*',
      element: (
        <LoadModule>
          <NotFound />
        </LoadModule>
      ),
    },
  ]);

  return <RouterProvider router={RoutesMeta} />;
}
